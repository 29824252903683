/* Add Animation */
@-webkit-keyframes slideIn {
  from {
    bottom: -300px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    bottom: -300px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

:root {
  --primaryBrand: #ff7300;
  --primarySoft: #ffb980;
  --primaryBrandLight: #ffeee0;
  --primaryWhite: #fff9f5;
  --darkGray: #aeaeae;
  --gray: #e5e5e5;
  --silver: #f6f6f6;
  --dark: #404042;
  --white: #ffffff;
  --error: #f91e1e;
}

//default styles
h1 {
  font-family: 'Lato', sans-serif;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.5em;
  letter-spacing: -0.03em;
  color: #404042;
  margin-bottom: 1.375rem;
}

//calendar style

.react-calendar__month-view__weekdays {
  font-family: Lato, sans-serif;
  font-weight: bold;
  text-decoration: none !important;
  font-size: 1rem;
  line-height: 1.375rem;
  text-align: center;
  color: #404042;
}

abbr[title] {
  text-decoration: none;
}

.react-calendar__tile:hover {
  background-color: #f7f7f7;
  border-radius: 50%;
}

.react-calendar__tile--active {
  border-radius: 50%;
  background: #ff7300 !important;
  font-weight: bold;
  color: white !important;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #e5e5e5 !important;
}

.react-calendar__month-view__weekdays__weekday {
  font-family: Lato, sans-serif;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.375rem;
  color: #404042;
  text-align: center;
  margin-bottom: 1.2rem;
}

.react-calendar__navigation__label {
  background: #ff7300;
  border-radius: 16px;
  font-family: Lato, sans-serif;
  font-weight: 900;
  text-align: center;
  font-size: 1rem;
  line-height: 1.5rem;
  color: white;
  padding: 0.25rem 1.5rem 0.25rem 1.5rem;
  cursor: pointer;
  border: none;
}

.react-calendar__navigation {
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-evenly;
}

.react-calendar__navigation__arrow {
  background-color: white !important;
  border: none;
  cursor: pointer;
  &:disabled {
    visibility: hidden;
  }
  &:active {
    outline: none;
    box-shadow: none;
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.react-calendar__navigation__next2-button {
  display: none;
}
.react-calendar__navigation__prev2-button {
  display: none;
}
